import React, { useEffect, useMemo } from 'react';
import { ButtonField, DatePicker, Styler, Paper, Anchor, Stacker, FlexGrid, RadioChip, RadioGroup, ScrollArea, PopoverAnchor, } from '@honeycomb/ui-core';
import { useGlobalContext, SearchFieldEnum, DateSearchType, TripType, FlightJourneyType } from '@honeycomb/data';
import { SearchDisplayType, useSearchContext } from '../SearchContext';
import { SearchDispatchActionType } from '../SearchReducer';
import { formatDateMonthYear } from '../../../utils/formatDate';
import { getDate, getDateSummary } from '../../../utils/getDateSummary';
import { toUTCDate } from '../../../utils/date-utils';
import { FieldDialog } from '../components/FieldDialog';
import { FieldErrorMessage } from '../components/FieldErrorMessage';
import { interjectResourceString } from '../../../utils/interjectResourceString';
import { FlexibleDateSelector } from './FlexibleDateRangeSelector';
import { parseBoolean } from '../../../utils/string-utils';
import { pluraliseCount } from '../../../utils/pluraliseCount';
import { DateRangeFooter } from './DateRangeFooter';
import { SearchFieldPopover } from '../components/SearchFieldPopover/SearchFieldPopover';
import { SearchFieldPopoverPanel } from '../components/SearchFieldPopover/SearchFieldPopoverPanel';
export function DateRangeSelector({ state, actions }) {
    var _a, _b, _c, _d, _e, _f;
    const { popupPlacement = 'bottom', isCached, selectedTripType } = state;
    const { onDone, onFieldFocused } = actions;
    const { openField, onOpen, onClose, displayType, searchDispatch, searchState, fieldValidity, searchError } = useSearchContext();
    const open = openField === SearchFieldEnum.DATES;
    const error = ((_a = fieldValidity[SearchFieldEnum.DATES]) === null || _a === void 0 ? void 0 : _a.error) || false;
    const validDates = ((_b = fieldValidity[SearchFieldEnum.DATES]) === null || _b === void 0 ? void 0 : _b.valid) || false;
    const checkInDateError = ((_c = fieldValidity[SearchFieldEnum.CHECKIN_DATE]) === null || _c === void 0 ? void 0 : _c.error) || false;
    const startDate = searchState.startDate ? new Date(searchState.startDate) : undefined;
    const wholeMonthStartDate = searchState.wholeMonthStartDate ? new Date(searchState.wholeMonthStartDate) : undefined;
    const endDate = searchState.endDate ? new Date(searchState.endDate) : undefined;
    const isOneWayFlightSearch = (searchState === null || searchState === void 0 ? void 0 : searchState.flightJourneyType) === FlightJourneyType.Oneway && selectedTripType === TripType.Flight;
    const flexibleDays = (_d = searchState.flexibleDays) !== null && _d !== void 0 ? _d : 0;
    const selectedRange = { from: startDate, to: endDate };
    const { resourceStrings: { HC_CHOOSE_DATES, HC_FLEXIBLE_DATES, HC_FLEXIBLE_DAY_TEXT, HC_FLEXIBLE_DAYS_TEXT, HC_FLEXIBLE_DAYS_EXACT_TEXT, SINGLENIGHT, NIGHTS, SEARCH_DATES_ERROR_EMPTY, SEARCH_DATES_ERROR_EMPTY_ONE_WAY, SEARCH_DATES_FIELD_LABEL_HOLIDAY, SEARCH_DATES_FIELD_LABEL_HOTELONLY, SEARCH_DATES_FIELD_LABEL_FLIGHTS_RETURN, SEARCH_DATES_RESETLINK_TEXT, HC_HOTEL_CHECKIN_VALIDATION_ERROR, HC_HOLIDAY_DEPARTURE_VALIDATION_ERROR, SEARCH_DATES_FIELD_LABEL_FLIGHTS_ONE_WAY, }, configSettings: { ProductCacheEnabled }, } = useGlobalContext();
    const isMultiSearch = parseBoolean(ProductCacheEnabled);
    const isFlexibleSearch = isMultiSearch && isCached;
    const popup = displayType === SearchDisplayType.POPUP;
    const dialog = displayType === SearchDisplayType.DIALOG;
    const initialMonth = selectedRange.from || new Date();
    const handleClickOrFocus = () => {
        if (!open) {
            onOpen(SearchFieldEnum.DATES);
        }
        onFieldFocused();
    };
    const checkInValidationError = searchState.tripType === TripType.Hotel
        ? HC_HOTEL_CHECKIN_VALIDATION_ERROR
        : HC_HOLIDAY_DEPARTURE_VALIDATION_ERROR;
    const fieldError = (React.createElement(FieldErrorMessage, { error: error || checkInDateError, message: error
            ? isOneWayFlightSearch
                ? SEARCH_DATES_ERROR_EMPTY_ONE_WAY
                : SEARCH_DATES_ERROR_EMPTY
            : checkInValidationError, "data-id": "date-range-error-message" }));
    const fieldLabel = useMemo(() => {
        switch (selectedTripType) {
            case TripType.Holiday:
                return SEARCH_DATES_FIELD_LABEL_HOLIDAY;
            case TripType.Hotel:
                return SEARCH_DATES_FIELD_LABEL_HOTELONLY;
            case TripType.Flight:
                return isOneWayFlightSearch
                    ? SEARCH_DATES_FIELD_LABEL_FLIGHTS_ONE_WAY
                    : SEARCH_DATES_FIELD_LABEL_FLIGHTS_RETURN;
            default:
                return SEARCH_DATES_FIELD_LABEL_HOTELONLY;
        }
    }, [
        selectedTripType,
        SEARCH_DATES_FIELD_LABEL_HOLIDAY,
        SEARCH_DATES_FIELD_LABEL_HOTELONLY,
        isOneWayFlightSearch,
        SEARCH_DATES_FIELD_LABEL_FLIGHTS_ONE_WAY,
        SEARCH_DATES_FIELD_LABEL_FLIGHTS_RETURN,
    ]);
    const handleChange = (range) => {
        searchDispatch({
            type: SearchDispatchActionType.SET_START_DATE,
            dateValue: toUTCDate(range === null || range === void 0 ? void 0 : range.from) || undefined,
        });
        searchDispatch({
            type: SearchDispatchActionType.SET_END_DATE,
            dateValue: toUTCDate(range === null || range === void 0 ? void 0 : range.to) || undefined,
        });
    };
    const handleStartDateChange = (date) => {
        searchDispatch({
            type: SearchDispatchActionType.SET_START_DATE,
            dateValue: toUTCDate(date) || undefined,
        });
    };
    const resetDates = (e) => {
        e.preventDefault();
        if (isFlexibleSearch) {
            searchDispatch({ type: SearchDispatchActionType.SET_FLEXIBLE_DAYS, flexibleDays: 0 });
        }
        searchDispatch({ type: SearchDispatchActionType.SET_START_DATE });
        searchDispatch({ type: SearchDispatchActionType.SET_END_DATE });
        searchDispatch({ type: SearchDispatchActionType.SET_WHOLE_MONTH_START_DATE });
        searchDispatch({ type: SearchDispatchActionType.SET_DURATION, duration: 7 });
    };
    useEffect(() => {
        if (!isCached) {
            searchDispatch({
                type: SearchDispatchActionType.SET_FLEXIBLE_DAYS,
                flexibleDays: undefined,
            });
            searchDispatch({
                type: SearchDispatchActionType.SET_DATE_SEARCH_TYPE,
                dateSearchType: DateSearchType.ChooseDate,
            });
        }
    }, [isCached, searchDispatch]);
    const dateSummary = isOneWayFlightSearch ? getDate(startDate, true) : getDateSummary(startDate, endDate, true);
    const getFieldLabel = React.useCallback(() => {
        if (searchState.dateSearchType !== DateSearchType.ChooseDate || !flexibleDays) {
            return fieldLabel;
        }
        const flexibleLabel = pluraliseCount(flexibleDays, {
            singular: HC_FLEXIBLE_DAY_TEXT,
            plural: interjectResourceString(HC_FLEXIBLE_DAYS_TEXT, flexibleDays),
        });
        return `${fieldLabel} ${flexibleLabel}`;
    }, [HC_FLEXIBLE_DAYS_TEXT, HC_FLEXIBLE_DAY_TEXT, fieldLabel, flexibleDays, searchState.dateSearchType]);
    const flexibleDateNightsSummary = wholeMonthStartDate
        ? `${formatDateMonthYear(wholeMonthStartDate)}, ${searchState.duration} ${searchState.duration === 1 ? SINGLENIGHT : NIGHTS}`
        : '';
    const summary = searchState.dateSearchType === DateSearchType.ChooseDate ? dateSummary : flexibleDateNightsSummary;
    const commonDatePickerProps = {
        mode: isOneWayFlightSearch ? 'single' : 'range',
        selectedRange,
        onSelectRange: handleChange,
        selectedDate: selectedRange === null || selectedRange === void 0 ? void 0 : selectedRange.from,
        onSelectDate: handleStartDateChange,
        defaultMonth: initialMonth,
        captionLayout: 'dropdown',
        toYear: initialMonth.getFullYear() + 3,
    };
    const resetButton = () => {
        if (searchState.dateSearchType === DateSearchType.ChooseMonth && !searchState.wholeMonthStartDate) {
            return null;
        }
        if (searchState.dateSearchType === DateSearchType.ChooseDate &&
            (!startDate || (!isOneWayFlightSearch && !endDate))) {
            return null;
        }
        return (React.createElement(Anchor, { component: "button", color: "text.link", size: "s", weight: "semiBold", ml: 3, onClick: resetDates, "data-id": "date-range-reset-button" }, SEARCH_DATES_RESETLINK_TEXT));
    };
    // Fexible days selection (radio buttons)
    const flexibleDaysSelector = (React.createElement(RadioGroup, { name: "flexible-days", value: flexibleDays.toString(), onChange: (event) => {
            const newValue = Number(event.currentTarget.value);
            searchDispatch({
                type: SearchDispatchActionType.SET_FLEXIBLE_DAYS,
                flexibleDays: newValue,
            });
        } },
        React.createElement(Stacker, { direction: "row", spacing: 2 }, [0, 1, 2, 3].map((value) => {
            return (React.createElement(RadioChip, { value: value.toString(), ChipProps: { size: 's' }, "data-id": `day-pill-${value}`, key: value.toString() }, value === 0
                ? HC_FLEXIBLE_DAYS_EXACT_TEXT
                : pluraliseCount(value, {
                    singular: HC_FLEXIBLE_DAY_TEXT,
                    plural: interjectResourceString(HC_FLEXIBLE_DAYS_TEXT, value),
                })));
        }))));
    // Date search type selection (radio buttons)
    const searchTypeToggle = (React.createElement(RadioGroup, { name: "date-search-type", value: searchState.dateSearchType || DateSearchType.ChooseDate, onChange: (event) => {
            searchDispatch({
                type: SearchDispatchActionType.SET_DATE_SEARCH_TYPE,
                dateSearchType: event.currentTarget.value,
            });
        } },
        React.createElement(FlexGrid, { container: true, spacing: 2, disableEqualOverflow: true },
            React.createElement(FlexGrid, { xs: 6 },
                React.createElement(Styler, { display: "flex", justifyContent: "flex-end" },
                    React.createElement(RadioChip, { value: DateSearchType.ChooseDate, "data-id": "choose-date" }, HC_CHOOSE_DATES))),
            React.createElement(FlexGrid, { xs: 6 },
                React.createElement(RadioChip, { value: DateSearchType.ChooseMonth, "data-id": "flexible-date" }, HC_FLEXIBLE_DATES)))));
    const getDatePicker = (numberOfMonths = 1) => {
        return (React.createElement(Paper, { radius: "s", display: "flex", justifyContent: "center" },
            React.createElement(DatePicker, Object.assign({ numberOfMonths: numberOfMonths }, commonDatePickerProps))));
    };
    const getDateRangeComponent = () => {
        const footer = (React.createElement(Paper, { radius: "s", px: 3, py: 2, ta: "center", border: 0, "data-id": "date-range-footer" },
            React.createElement(React.Fragment, null,
                React.createElement(DateRangeFooter, { selectedTripType: selectedTripType }),
                resetButton())));
        if (!isFlexibleSearch) {
            return (React.createElement(Stacker, { spacing: 3 },
                getDatePicker(2),
                footer));
        }
        return (React.createElement(Stacker, { spacing: 3 },
            searchTypeToggle,
            searchState.dateSearchType === DateSearchType.ChooseDate && getDatePicker(2),
            searchState.dateSearchType === DateSearchType.ChooseMonth && React.createElement(FlexibleDateSelector, null),
            searchState.dateSearchType === DateSearchType.ChooseDate && (React.createElement(FlexGrid, { container: true, spacing: 2, alignItems: "center", disableEqualOverflow: true },
                React.createElement(FlexGrid, { xs: "hold" }, flexibleDaysSelector),
                React.createElement(FlexGrid, { xs: "fill" }, footer))),
            searchState.dateSearchType === DateSearchType.ChooseMonth && footer));
    };
    const getMobileDateRangeComponent = () => {
        if (!isFlexibleSearch) {
            return getDatePicker();
        }
        return (React.createElement(Stacker, { spacing: 3 },
            searchTypeToggle,
            searchState.dateSearchType === DateSearchType.ChooseDate && getDatePicker(),
            searchState.dateSearchType === DateSearchType.ChooseMonth && React.createElement(FlexibleDateSelector, null)));
    };
    const button = (React.createElement(ButtonField, { label: getFieldLabel(), value: summary, onClick: handleClickOrFocus, onFocus: () => popup && handleClickOrFocus(), fullWidth: true, error: error, size: { xs: 'm', m: 'l' }, variant: "alternative" }));
    return (React.createElement(React.Fragment, null,
        popup && (React.createElement(SearchFieldPopover, { open: open, onClose: () => onClose(), placement: popupPlacement, error: searchError },
            React.createElement(PopoverAnchor, null, button),
            React.createElement(SearchFieldPopoverPanel, { width: 760, disableScroll: true }, getDateRangeComponent()))),
        dialog && (React.createElement(React.Fragment, null,
            button,
            React.createElement(FieldDialog, { open: open, onClose: onClose, title: fieldLabel, ctaProps: {
                    onClick: onDone,
                    label: 'Next',
                    disabled: !validDates,
                }, resetProps: {
                    onClick: resetDates,
                    label: 'Reset',
                    disabled: !((_f = (_e = startDate !== null && startDate !== void 0 ? startDate : endDate) !== null && _e !== void 0 ? _e : wholeMonthStartDate) !== null && _f !== void 0 ? _f : flexibleDays),
                }, footerContent: React.createElement(React.Fragment, null,
                    isFlexibleSearch && searchState.dateSearchType === DateSearchType.ChooseDate && (React.createElement(ScrollArea, { mx: -3, mb: 3 },
                        React.createElement(Styler, { px: 3, display: "flex", justifyContent: "center" }, flexibleDaysSelector))),
                    React.createElement(Paper, { radius: "s", px: 3, py: 2, ta: "center", bg: "background.offset", border: 0, mb: 3, "data-id": "date-range-footer" },
                        React.createElement(DateRangeFooter, { selectedTripType: selectedTripType }))) }, getMobileDateRangeComponent()))),
        fieldError));
}
