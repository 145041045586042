import React, { useCallback, useEffect, useState } from 'react';
import { formatDateForAPI, SearchFieldEnum, useGlobalContext } from '@honeycomb/data';
import { Anchor, ButtonField, Paper, PopoverAnchor, Styler, TypographyBody } from '@honeycomb/ui-core';
import { getDayDiff, getFormattedDate, DateFormats } from '../../../utils/date-utils';
import { FieldDialog } from '../components/FieldDialog';
import { FieldErrorMessage } from '../components/FieldErrorMessage';
import { DateTimeSelector } from '../../../components/DateTimeSelector/DateTimeSelector';
import { useFormatDate, useFormatTime } from '../../../utils/useFormatDateTime';
import { SearchDispatchActionType } from '../SearchReducer';
import { SearchDisplayType, useSearchContext } from '../SearchContext';
import { SearchFieldPopover } from '../components/SearchFieldPopover/SearchFieldPopover';
import { SearchFieldPopoverPanel } from '../components/SearchFieldPopover/SearchFieldPopoverPanel';
const isOpen = (dateType, openField) => {
    if (dateType === 'start' && openField === SearchFieldEnum.STARTDATE) {
        return true;
    }
    if (dateType === 'end' && openField === SearchFieldEnum.ENDDATE) {
        return true;
    }
    return false;
};
const getDateValue = (dateType, searchState) => {
    const dateStr = dateType === 'start' ? searchState.startDate : searchState.endDate;
    return dateStr ? new Date(dateStr) : null;
};
const isValidInterval = (searchState) => {
    if (searchState.startDate && searchState.endDate) {
        const diff = getDayDiff(getFormattedDate(DateFormats.None, searchState.startDate), getFormattedDate(DateFormats.None, searchState.endDate));
        if (diff <= 0) {
            return false;
        }
        return true;
    }
    return false;
};
export function TransferDateTimePicker({ dateType, disabled = false, onDone, }) {
    var _a, _b;
    const { resourceStrings: { SEARCH_STEP_NEXTBUTTONLABEL, SEARCH_DATES_RESETLINK_TEXT, FLIGHT_ARRIVAL_DATE, FLIGHT_DEPARTURE_DATE, SEARCH_ARRIVALDATE_PROMPT, SEARCH_DEPARTUREDATE_PROMPT, }, } = useGlobalContext();
    const { searchDispatch, searchState, fieldValidity, onClose, onOpen, openField, displayType, searchError } = useSearchContext();
    // Get the current date value from the search state
    const dateValue = getDateValue(dateType, searchState);
    const currentSearchField = dateType === 'start' ? SearchFieldEnum.STARTDATE : SearchFieldEnum.ENDDATE;
    const formattedDate = useFormatDate({ date: dateValue });
    const formattedTime = useFormatTime({ date: dateValue });
    const formattedDateTime = `${formattedDate} - ${formattedTime}`;
    const open = isOpen(dateType, openField);
    const [minDate, setMinDate] = useState(new Date());
    const popup = displayType === SearchDisplayType.POPUP;
    const dialog = displayType === SearchDisplayType.DIALOG;
    const fieldLabel = dateType === 'start' ? FLIGHT_ARRIVAL_DATE : FLIGHT_DEPARTURE_DATE;
    const fieldPrompt = dateType === 'start' ? SEARCH_ARRIVALDATE_PROMPT : SEARCH_DEPARTUREDATE_PROMPT;
    const error = (!disabled && ((_a = fieldValidity[currentSearchField]) === null || _a === void 0 ? void 0 : _a.error)) || false;
    const handleChange = (date) => {
        if (!date) {
            return;
        }
        if (currentSearchField === SearchFieldEnum.STARTDATE) {
            searchDispatch({
                type: SearchDispatchActionType.SET_START_DATE,
                dateValue: formatDateForAPI(date),
            });
            return;
        }
        if (currentSearchField === SearchFieldEnum.ENDDATE) {
            searchDispatch({ type: SearchDispatchActionType.SET_END_DATE, dateValue: formatDateForAPI(date) });
        }
    };
    const handleReset = useCallback(() => {
        searchDispatch({
            type: currentSearchField === SearchFieldEnum.STARTDATE
                ? SearchDispatchActionType.SET_START_DATE
                : SearchDispatchActionType.SET_END_DATE,
        });
    }, [currentSearchField, searchDispatch]);
    useEffect(() => {
        if (currentSearchField === SearchFieldEnum.ENDDATE) {
            const newMinDate = searchState.startDate ? new Date(searchState.startDate) : new Date();
            newMinDate.setDate(new Date(newMinDate).getDate() + 1);
            setMinDate(newMinDate);
        }
        if (searchState.startDate && searchState.endDate && !isValidInterval(searchState)) {
            searchDispatch({
                type: SearchDispatchActionType.SET_END_DATE,
            });
        }
    }, [searchState, currentSearchField, searchDispatch]);
    useEffect(() => {
        if (disabled) {
            searchDispatch({
                type: currentSearchField === SearchFieldEnum.STARTDATE
                    ? SearchDispatchActionType.SET_START_DATE
                    : SearchDispatchActionType.SET_END_DATE,
            });
        }
    }, [currentSearchField, disabled, searchDispatch]);
    const initialMonth = currentSearchField === SearchFieldEnum.STARTDATE ? dateValue || new Date() : dateValue || minDate;
    const handleClickOrFocus = () => {
        if (!open) {
            onOpen(currentSearchField);
        }
    };
    const fieldError = React.createElement(FieldErrorMessage, { error: error, message: "Please add a date", "data-id": "dates-error-message" });
    const dateTimePicker = (React.createElement(DateTimeSelector, { value: dateValue, onChange: handleChange, minDate: minDate, defaultMonth: initialMonth, captionLayout: "dropdown", toYear: initialMonth.getFullYear() + 3, withTabs: dialog, ClockProps: {
            referenceDate: minDate,
        } }));
    const button = (React.createElement(ButtonField, { label: fieldLabel, value: dateValue ? formattedDateTime : undefined, fullWidth: true, disabled: disabled, onClick: handleClickOrFocus, onFocus: () => popup && handleClickOrFocus(), error: (!disabled && ((_b = fieldValidity[currentSearchField]) === null || _b === void 0 ? void 0 : _b.error)) || false, size: { xs: 'm', m: 'l' }, variant: "alternative" }));
    return (React.createElement(React.Fragment, null,
        popup && (React.createElement(SearchFieldPopover, { open: open, onClose: () => onClose(), error: searchError },
            React.createElement(PopoverAnchor, null, button),
            React.createElement(SearchFieldPopoverPanel, null,
                dateTimePicker,
                React.createElement(Paper, { radius: "s", mt: 4, p: 2, border: 0, display: "flex", justifyContent: "center" }, dateValue ? (React.createElement(React.Fragment, null,
                    React.createElement(TypographyBody, { component: "span", size: "s", weight: "bold" }, formattedDateTime),
                    React.createElement(Anchor, { component: "button", color: "text.link", size: "s", weight: "semiBold", ml: 3, onClick: handleReset }, SEARCH_DATES_RESETLINK_TEXT))) : (React.createElement(TypographyBody, { component: "span", size: "s", weight: "semiBold" }, fieldPrompt)))))),
        dialog && (React.createElement(React.Fragment, null,
            button,
            React.createElement(FieldDialog, { open: open, onClose: onClose, title: fieldLabel, ctaProps: {
                    label: SEARCH_STEP_NEXTBUTTONLABEL,
                    onClick: onDone,
                    disabled: !dateValue,
                }, resetProps: {
                    onClick: handleReset,
                    label: 'Reset',
                }, footerContent: React.createElement(Paper, { radius: "s", px: 3, py: 2, ta: "center", bg: "background.offset", border: 0, mb: 3 }, dateValue ? (React.createElement(TypographyBody, { component: "span", size: "s", weight: "bold" }, formattedDateTime)) : (React.createElement(TypographyBody, { component: "span", size: "s", weight: "semiBold" }, fieldPrompt))) },
                React.createElement(Styler, { h: "100%", display: "flex", justifyContent: "center" }, dateTimePicker)))),
        fieldError));
}
